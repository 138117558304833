const React = require("react")
const $ = require("jquery")

exports.onRouteUpdate = ({ location }) => {
  if (location.pathname == "/") {
    $("#splash-logo").delay(1500).fadeOut('slow');//ロゴを1.2秒でフェードアウトする記述
    //$("#splash-logo").delay(0).fadeOut('slow');//ロゴを1.2秒でフェードアウトする記述

    //=====ここからローディングエリア（splashエリア）を1.5秒でフェードアウトした後に動かしたいJSをまとめる
    $("#splash").delay(2000).fadeOut('slow', function () {//ローディングエリア（splashエリア）を1.5秒でフェードアウトする記述

      $('body').addClass('appear');//フェードアウト後bodyにappearクラス付与

    });
  }
};